	/*
  	Flaticon icon font: Flaticon
  	Creation date: 14/02/2019 18:35
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-user:before { content: "\f100"; }
.flaticon-turn-off:before { content: "\f101"; }
.flaticon-chat-comment-oval-speech-bubble-with-text-lines:before { content: "\f102"; }
.flaticon-list:before { content: "\f103"; }
.flaticon-settings-work-tool:before { content: "\f104"; }
.flaticon-gear-loading:before { content: "\f105"; }
.flaticon-back:before { content: "\f106"; }
.flaticon-next:before { content: "\f107"; }
.flaticon-down-arrow:before { content: "\f108"; }
.flaticon-back-1:before { content: "\f109"; }
.flaticon-next-1:before { content: "\f10a"; }
.flaticon-upload:before { content: "\f10b"; }
.flaticon-download:before { content: "\f10c"; }
.flaticon-arrow:before { content: "\f10d"; }
.flaticon-up-arrow:before { content: "\f10e"; }
.flaticon-back-arrow-circular-symbol:before { content: "\f10f"; }
.flaticon-two-arrows-pointing-up:before { content: "\f110"; }
.flaticon-search:before { content: "\f111"; }
.flaticon-menu:before { content: "\f112"; }
.flaticon-dashboard:before { content: "\f113"; }
.flaticon-open-book:before { content: "\f114"; }
.flaticon-books:before { content: "\f115"; }
.flaticon-calendar:before { content: "\f116"; }
.flaticon-shopping-list:before { content: "\f117"; }
.flaticon-mortarboard:before { content: "\f118"; }
.flaticon-chat:before { content: "\f119"; }
.flaticon-planet-earth:before { content: "\f11a"; }
.flaticon-menu-1:before { content: "\f11b"; }
.flaticon-settings:before { content: "\f11c"; }
.flaticon-reload:before { content: "\f11d"; }
.flaticon-magnifying-glass:before { content: "\f11e"; }
.flaticon-ring:before { content: "\f11f"; }
.flaticon-classmates:before { content: "\f120"; }
.flaticon-multiple-users-silhouette:before { content: "\f121"; }
.flaticon-couple:before { content: "\f122"; }
.flaticon-bed:before { content: "\f123"; }
.flaticon-bus-side-view:before { content: "\f124"; }
.flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler:before { content: "\f125"; }
.flaticon-mail:before { content: "\f126"; }
.flaticon-technological:before { content: "\f127"; }
.flaticon-checklist:before { content: "\f128"; }
.flaticon-doctor:before { content: "\f129"; }
.flaticon-script:before { content: "\f12a"; }
.flaticon-money:before { content: "\f12b"; }
.flaticon-more-button-of-three-dots:before { content: "\f12c"; }
.flaticon-more-button-interface-symbol-of-three-horizontal-aligned-dots:before { content: "\f12d"; }
.flaticon-earth-globe:before { content: "\f12e"; }
.flaticon-percentage-discount:before { content: "\f12f"; }