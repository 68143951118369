.fastinfo {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: aliceblue;
}
.fastinfo b {
  font-size: 20px;
}
